<script>
  export let bgColor;
  let bgColorClass = bgColor ? `color-bg-${bgColor}` : "";

  export let bgImage;
  export let style = bgImage ? `background-image: url(${bgImage});` : null;

  export let mobilePaddingCollapse;
  export let noContainer;
  export let noPad;
  export let sidePad;
  export let squarePad;
</script>

<section
  class="section {bgColorClass}"
  class:bgImage
  class:mobilePaddingCollapse
  class:noContainer
  class:noPad
  class:sidePad
  class:squarePad
  {style}
>
  <slot />
</section>

<style>
  section {
    text-align: center;
  }

  section.bgImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3rem;
  }

  section.noPad {
    padding: 0;
  }

  section.sidePad {
    padding: 0 1.5rem;
  }

  section.squarePad {
    padding: 1.5rem;
  }

  /* TODO: hardcoded breakpoint */
  @media screen and (max-width: 768px) {
    section.mobilePaddingCollapse {
      padding: 0;
    }
  }
</style>
