<script>
  import { fade } from "svelte/transition";

  import { posts } from "../blog/facade";
  import Assessment from "../components/Assessment.svelte";
  import BlogPostCard from "../components/BlogPostCard.svelte";
  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import Section from "../components/Section.svelte";

  const ratingCtas = [
    "Focus here first",
    "Room for improvement",
    "You're doing well",
  ];

  let results = null;
  function onComplete({ answersWithColors, assessment, responses, sections }) {
    results = { answersWithColors, assessment, responses, sections };
  }

  const relevantPostStubs = [
    "learning-to-listen-deeply",
    "the-art-of-listening",
  ];

  const relevantPosts = posts(true).filter((p) =>
    relevantPostStubs.includes(p.stub)
  );
</script>

<Assessment {onComplete} stub="listening-assessment">
  {#if results}
    <Section>
      <Container>
        <div in:fade={{ duration: 500 }}>
          <h2>Results</h2>
          <div class="key">
            <ul>
              {#each ratingCtas as cta, c}
                <li
                  style="background-color: #{results.answersWithColors[c]
                    .color}"
                >
                  {cta}
                </li>
              {/each}
            </ul>
          </div>
          <div class="results color-bg-snow">
            {#each results.responses as { answers, icon, section }}
              <h3>
                <Icon i={icon} />
                {section.name}
              </h3>
              <ul>
                {#each section.questions as question, i}
                  <li class="bullet-{answers[i]}">
                    {question.text}
                  </li>
                {/each}
              </ul>
            {/each}
          </div>
        </div>

        <div class="ctas">
          <p>
            If becoming a better listener and coach sounds valuable, you might
            want to check out our team workshops on these subjects...
          </p>

          <div class="buttons">
            <a
              class="button is-medium is-gold"
              href="/w/coaching-workshop"
              target="_blank"
            >
              Coaching
            </a>
            <a
              class="button is-medium is-gold"
              href="/w/1on1s-and-pdps"
              target="_blank"
            >
              Effective 1-on-1s
            </a>
          </div>
        </div>
      </Container>
    </Section>
  {/if}
</Assessment>

<Section>
  <Container innerPad>
    <div class="others">
      <hr />
      <div class="columns is-centered">
        {#each relevantPosts as post}
          <div class="column is-one-third">
            <BlogPostCard {post} />
          </div>
        {/each}
      </div>
    </div>
  </Container>
</Section>

<style>
  .key {
    margin: 0 auto 1.5rem;
    width: fit-content;
  }

  .key ul {
    width: fit-content;
  }

  .key ul li {
    border-radius: 5px;
    color: black;
    font-size: var(--ps-fontsize-tiny);
    font-weight: bold;
    margin: 5px 10px;
    opacity: 75%;
    padding: 10px 20px;
  }

  .results {
    border-radius: 5px;
    margin: 0 auto;
    max-width: 680px;
    padding: 1em 1.5em;
    text-align: left;
  }

  .results h3 {
    display: flex;
    font-size: var(--ps-fontsize-small);
    margin-bottom: 0.75rem;
  }

  .results h3 :global(.icon) {
    margin-right: 0.5rem;
  }

  .results ul {
    margin-bottom: 2rem;
  }

  .results ul li {
    list-style-type: disc;
    margin-bottom: 0.5rem;
    margin-left: 2.5rem;
  }

  .results ul li::marker {
    font-size: 1.5em;
  }

  .results ul li.bullet-0::marker {
    color: #f7322c;
  }
  .results ul li.bullet-1::marker {
    color: #ffcf59;
  }
  .results ul li.bullet-2::marker {
    color: #1fbc1f;
  }

  .ctas {
    margin: 2rem auto 0;
    max-width: 600px;
  }

  .ctas a:not(.button) {
    text-decoration-line: underline;
  }

  .buttons {
    justify-content: center;
  }

  h2 {
    font-size: var(--ps-fontsize-medlarge);
    margin-bottom: 1.5rem;
  }

  @keyframes bgBounceThroughGold {
    0% {
      background-color: inherit;
    }
    50% {
      background-color: var(--ps-colors-gold);
    }
    100% {
      background-color: inherit;
    }
  }

  .flashGold {
    animation: bgBounceThroughGold 1s;
  }
</style>
