<script>
  import QueryString from "query-string";
  import { fade } from "svelte/transition";

  import Assessment from "../components/Assessment.svelte";
  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import Section from "../components/Section.svelte";

  const journeys = {
    // survey -> results (descriptions, no tips) -> CTAs (book signup, disco call for team wkshp, reg for solo wkshp)
    j1: {
      showDescriptions: true,
      ctaBook: true,
      ctaWorkshop: true,
      referBook: false,
      referWorkshop: false,
    },
    // book -> survey -> results (descriptions, no tips) -> "guidance in book" + CTAs (disco-teams, reg-solo)
    j2: {
      showDescriptions: true,
      ctaBook: false,
      ctaWorkshop: true,
      referBook: false, // switched this off as it was challenging to lay it out between the results panel and the workshop CTA
      referWorkshop: false,
    },
    // workshop -> survey -> results (no descriptions, no tips)
    j3: {
      showDescriptions: false,
      ctaBook: false,
      ctaWorkshop: false,
      referBook: false,
      referWorkshop: false,
    },
    // pre-workshop -> survey -> results (no descriptions, no tips) -> "we'll explore meaning and guidance in wkshp"
    j4: {
      showDescriptions: false,
      ctaBook: false,
      ctaWorkshop: false,
      referBook: false,
      referWorkshop: true,
    },
  };

  let journey = journeys.j1;
  if (typeof window !== "undefined") {
    const qJourneys = Object.keys(QueryString.parse(window.location.search));
    for (const j in journeys) {
      if (qJourneys.includes(j)) {
        journey = journeys[j];
        break;
      }
    }
  }

  let analysis = [];
  let resps = [];

  function onComplete({ answersWithColors, assessment, responses, sections }) {
    for (const { answers, section } of responses) {
      const max = answers.length * (answersWithColors.length - 1);
      const total = answers.reduce((sum, r) => sum + r, 0);

      const ratio = total / max;
      const summary = ratio < 0.4 ? "low" : ratio < 0.7 ? "moderate" : "high";

      const klass = {
        low: "success",
        moderate: "warning",
        high: "danger",
      }[summary];

      analysis.push({
        klass,
        max,
        name: section.name,
        ratio,
        summary,
        total,
      });
    }

    analysis = analysis;
    resps = responses;
  }

  let jumpName = null;
  function jumpDown(e) {
    jumpName = e.target.href.split("#")[1].replace("%20", " ");
    setTimeout(() => (jumpName = null), 1000);
  }
</script>

<Assessment {onComplete} stub="burnout-assessment">
  {#if analysis}
    <Section>
      <Container>
        <div in:fade={{ duration: 500 }}>
          <a name="results">
            <h2>Results</h2>
          </a>
          <table class="results color-bg-mid-grey">
            {#each analysis as a}
              <tr>
                {#if journey.showDescriptions}
                  <td><a href="#{a.name}" on:click={jumpDown}>{a.name}</a></td>
                {:else}
                  <td>{a.name}</td>
                {/if}
                <td>
                  <p class="has-text-{a.klass}">
                    {a.summary.charAt(0).toUpperCase() + a.summary.slice(1)}
                  </p>
                  <progress
                    class="progress is-small is-{a.klass}"
                    value={a.total}
                    max={a.max}
                  >
                    {a.total}/{a.max}
                  </progress>
                </td>
              </tr>
            {/each}
          </table>
        </div>
      </Container>

      {#if journey.referBook || journey.referWorkshop}
        <Container>
          <div class="columns is-centered">
            <div class="column is-two-thirds refer-book">
              {#if journey.referBook}
                <p>
                  For guidance on what you might do next, refer back to the
                  Burnout Guide.
                </p>
              {:else}
                <p>
                  Make a <strong>note</strong> or take a
                  <strong>screenshot</strong> of your results.
                </p>
                <p>We'll explore these indicators during the workshop.</p>
              {/if}
            </div>
          </div>
        </Container>
      {/if}

      {#if journey.ctaBook || journey.ctaWorkshop}
        <Container innerPad>
          <div class="columns is-centered">
            {#if journey.ctaBook}
              <div class="column is-one-third color-bg-platinum">
                <h3>Burnout Guide</h3>
                <p>
                  Learn all about these indicators and what you can do about
                  them.
                </p>
                <a class="button" href="/burnout-guide" target="_blank">
                  Download the guide
                </a>
              </div>
            {/if}
            {#if journey.ctaWorkshop}
              <div
                class="column color-bg-light-gold"
                class:is-one-third={journey.ctaBook}
                class:is-two-thirds={!journey.ctaBook}
              >
                <h3>Burnout Workshop</h3>
                <p>Check out our workshop that will help you go deeper.</p>
                <a class="button is-dark" href="/w/wellbeing" target="_blank">
                  See the workshop
                </a>
              </div>
            {/if}
          </div>
        </Container>
      {/if}

      {#if journey.showDescriptions}
        {#each resps as { icon, section }}
          <Container>
            <div class="columns is-centered">
              <div
                class="column is-two-thirds definition"
                class:flashGold={section.name == jumpName}
              >
                <a name={section.name}>
                  <h3>
                    <Icon i={icon} />
                    {section.name}
                  </h3>
                </a>
                <p>
                  {section.description}
                </p>
                <p class="back-to-top">
                  <a href="#results">back to results</a>
                </p>
              </div>
            </div>
          </Container>
        {/each}
      {/if}
    </Section>
  {/if}
</Assessment>

<style>
  .results {
    border-radius: 5px;
    margin: 0 auto 1.5rem;
  }

  .results td {
    padding: 10px;
    text-align: left;
  }

  .results td p {
    margin-bottom: 0.5rem;
  }

  .definition {
    align-items: flex-start;
    text-align: left;
  }

  .definition h3 {
    margin-bottom: 1.5rem;
  }

  .definition h3 :global(.icon) {
    color: var(--ps-colors-gold);
    margin-right: 5px;
  }

  a {
    text-decoration: underline;
    text-decoration-color: var(--ps-colors-light-grey);
  }

  a:hover {
    text-decoration-color: var(--ps-colors-gold);
  }

  a.button {
    text-decoration: none;
  }

  a[name] {
    cursor: inherit;
    text-decoration: none;
  }

  h2 {
    font-size: var(--ps-fontsize-medlarge);
    margin-bottom: 1.5rem;
  }

  @keyframes bgBounceThroughGold {
    0% {
      background-color: inherit;
    }
    50% {
      background-color: var(--ps-colors-gold);
    }
    100% {
      background-color: inherit;
    }
  }

  .flashGold {
    animation: bgBounceThroughGold 1s;
  }
</style>
