export const coachingPartners = [
  "google",
  "riot",
  "atlassian",
  "facebook",
  "amazon",
  "readdle",
  "indeed",
  "gusto",
  "walmart-labs",
  "salesforce",
  "dell",
  "zeus",
  "workday",
  "unity",
  "united-healthcare",
  "spring-labs",
  "pocket",
  "mindshow",
  "bread",
  "good-rx",
  "city-of-hope",
  "contentful",
  "that-game-company",
  "earnup",
  "morningstar",
  "npr",
  "task-rabbit",
  "wells-fargo",
  "which",
  "abc-fitness",
  "apartment-therapy",
  "go-daddy",
  "netchex",
  "stellar-development-foundation",
  "course-hero",
  "palantir",
  "robinhood",
  "sky-specs",
  "work-fusion",
  "ao",
  "fluent-forever",
  "goldman-sachs",
  "lyft",
  "proofpoint",
  "superhuman",
  "clairvoyant",
  "dropbox",
  "teal",
  "booking-com",
  "crunchbase",
  "for-all-secure",
  "frame-ai",
  "spin",
  "tipser",
  "forto",
  "gamebreaking-studios",
  "my-builder",
  "credit-karma",
  "kiva",
  // "news-uk",
  "9fin",
  "saas-group",
  "aurora-solar",
  "guest-ready",
  "hometap",
  "juni",
  "upstart",
  "gather",
  "innovative-interfaces",
  "m2gen",
  "sprious",
  "pocket-worlds",
  "blissway",
  "shortcut",
  "geico",
  "urban-jungle",
  "bloomberg",
  "ecosia",
  "pay-pal",
  "t-mobile",
  "greenscreens-ai",
];

export const consultingPartners = [
  "google",
  "american-express",
  "indeed",
  // "platypus",
  "honda",
  "moss-adams",
  "sensu",
  "riot",
  "atlassian",
  "tech-ladies",
  "facebook",
  "stream-sets",
  "spring-labs",
  "readdle",
  "kinship",
  "movement-strategy",
  "that-game-company",
  "nova-credit",
  "apartment-therapy",
  "striking-distance-studios",
  "allied-electronics",
  "andela",
  "wall-street-journal",
  "sky-specs",
  "swiftly",
  "editorialist-yx",
  "empirx-health",
  "first-round",
  "fluent-forever",
  "clairvoyant",
  "allergan-data-labs",
  "aurora-solar",
  "course-hero",
  "for-all-secure",
  "machina-labs",
  "newzoo",
  "secret-location",
  "tipser",
  "upsolver",
  "upstart",
  "my-builder",
  "guest-ready",
  "hometap",
  "juni",
  "meister-labs",
  "pocket-worlds",
  "9fin",
  "saas-group",
  "activ-trak",
  "atb-financial",
  "bark",
  "cherry-ventures",
  "go-links",
  "okteto",
  "sanity",
  "shortcut",
  "go-cardless",
  "riskified",
  "urban-jungle",
  // "bitso",
  // "coda-payments",
];

export const allPartners = [];
for (const p of coachingPartners.concat(consultingPartners)) {
  if (!allPartners.includes(p)) allPartners.push(p);
}
