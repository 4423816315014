<script>
  import QueryString from "query-string";

  import { airtableData } from "../airtable/compiled";
  import Carousel from "../components/Carousel.svelte";
  import Container from "../components/Container.svelte";
  import ImageCarouselCard from "../components/ImageCarouselCard.svelte";
  import LogoGrid from "../components/LogoGrid.svelte";
  import Markdown from "../components/Markdown.svelte";
  import Modal from "../components/Modal.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";
  import WorkshopNav from "../components/WorkshopNav.svelte";
  import { galleriesByName } from "../galleries/compiled";
  import { consultingPartners as partners } from "../partnerLogos";
  import { undrawImageAlt } from "../undrawImageAlts";

  export let id;

  let participant = null;
  if (typeof window !== "undefined") {
    let certId = QueryString.parse(window.location.search).certId;
    participant = airtableData.participants.find((p) => p.certId == certId);
  }

  const programsByStub = {};
  for (const p of airtableData.programs) programsByStub[p.stub] = p;

  $: program = programsByStub[id] || {};
  $: supImages = [program.supImage1, program.supImage2].filter(Boolean);
  $: gallery = galleriesByName[program.gallery];

  $: testimonials = airtableData.testimonials.filter(
    (t) =>
      t.pagePaths[0] == `/p/${program.testimonialStubOverride}` ||
      t.pagePaths[0] == `/p/${program.stub}`
  );

  let formModalActive = false;
  let innerHeight;

  $: quoteTitleHeights = [0, 0, 0, 0];
  $: quoteTitleHeights1 = [0, 0, 0];
  $: quoteTitleHeights2 = [0, 0, 0];
  $: quoteTitleHeights3 = [0, 0, 0];
</script>

<svelte:window bind:innerHeight />

{#if formModalActive}
  <Modal bind:active={formModalActive} spinner>
    <iframe
      src="https://airtable.com/embed/shrNKImTFjRoqNNIL?{encodeURIComponent(
        'prefill_Interested In'
      )}={encodeURIComponent(program.name + ' Program')}"
      title="Register Interest"
      height={innerHeight - 150}
      width="100%"
    >
      Loading form...
    </iframe>
  </Modal>
{/if}

<Section bgColor="dark-grey">
  {#if participant}
    <Container>
      <img
        alt="{participant.name}'s Certificate"
        class="cert"
        src="/certs/{participant.certId}.png"
      />
    </Container>
  {/if}
  <Container>
    <img
      alt={undrawImageAlt(program.image)}
      class="head mb1r"
      src="/img/illustrations/gold/undraw_{program.image}.svg"
    />
    <h1>{program.name} Program</h1>
    <p class="strapline">
      {@html (program.strapline || "").replace(/\n/g, "<br/>")}
    </p>
    <NavLink button buttonClass="is-gold is-medium" to="/book-disco-call">
      Book a call
    </NavLink>
  </Container>
</Section>

{#if program.headQuote}
  <Section bgColor="platinum" noPad>
    <Container innerPad>
      <Quote
        citation={program.headQuoteCitation}
        midsize
        paras={[program.headQuote]}
      />
    </Container>
  </Section>
{/if}

{#if program.compsAtTop}
  {#if program.s1Name != "HIDE" || program.s2Name != "HIDE"}
    <Section>
      <Container>
        <p>
          Our <strong>{program.name}</strong> program is built from these individual
          workshops...
        </p>
        <WorkshopNav
          groupBy={(w) =>
            w.programsS1.map((p) => p.stub).includes(program.stub)
              ? program.s1Name
              : program.s2Name}
          groups={[program.s1Name, program.s2Name].filter((n) => n != "HIDE")}
          progStub={program.stub}
        />
      </Container>
    </Section>
  {/if}
{/if}

<Section bgColor="snow" noPad>
  <Container vertPad>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <div class="description">
          <Markdown
            content={program.description}
            dlButtonClass="is-gold"
            dlButtonLabel="Download program info"
            {testimonials}
          />
        </div>
        <div class="buttons">
          <NavLink button buttonClass="is-gold" to="/book-disco-call">
            Book a call
          </NavLink>
        </div>
      </div>
    </div>
  </Container>
</Section>

{#if !program.compsAtTop}
  {#if program.s1Name != "HIDE" || program.s2Name != "HIDE"}
    <Section bgColor="dark-grey">
      <Container>
        <p>
          Our <strong>{program.name}</strong> program is built from these individual
          workshops...
        </p>
        <WorkshopNav
          groupBy={(w) =>
            w.programsS1.map((p) => p.stub).includes(program.stub)
              ? program.s1Name
              : program.s2Name}
          groups={[program.s1Name, program.s2Name].filter((n) => n != "HIDE")}
          progStub={program.stub}
        />
      </Container>
    </Section>
  {/if}
{/if}

<Section>
  <Container>
    <LogoGrid {partners} />
    {#if testimonials.length <= 4}
      <div class="columns">
        {#each testimonials.slice(0, 4) as t, i}
          <div class="column color-bg-mid-grey spaced">
            <Quote
              citation={t.citationOverride || t.citation}
              photo={t.image}
              paras={t.text.split("\n")}
              title={t.title}
              bind:titleHeight={quoteTitleHeights[i]}
              titleHeightMax={Math.max(...quoteTitleHeights)}
            />
          </div>
        {/each}
      </div>
    {:else}
      <div class="columns">
        {#each testimonials.slice(0, 3) as t, i}
          <div class="column color-bg-mid-grey spaced">
            <Quote
              citation={t.citationOverride || t.citation}
              photo={t.image}
              paras={t.text.split("\n")}
              title={t.title}
              bind:titleHeight={quoteTitleHeights1[i]}
              titleHeightMax={Math.max(...quoteTitleHeights1)}
            />
          </div>
        {/each}
      </div>
      <div class="columns">
        {#each testimonials.slice(3, 6) as t, i}
          <div class="column color-bg-mid-grey spaced">
            <Quote
              citation={t.citationOverride || t.citation}
              photo={t.image}
              paras={t.text.split("\n")}
              title={t.title}
              bind:titleHeight={quoteTitleHeights2[i]}
              titleHeightMax={Math.max(...quoteTitleHeights2)}
            />
          </div>
        {/each}
      </div>
      <div class="columns">
        {#each testimonials.slice(6, 9) as t, i}
          <div class="column color-bg-mid-grey spaced">
            <Quote
              citation={t.citationOverride || t.citation}
              photo={t.image}
              paras={t.text.split("\n")}
              title={t.title}
              bind:titleHeight={quoteTitleHeights3[i]}
              titleHeightMax={Math.max(...quoteTitleHeights3)}
            />
          </div>
        {/each}
      </div>
    {/if}
  </Container>
  <Container innerPad>
    <div class="buttons">
      <NavLink button buttonClass="is-dark" to="/why-us-training">
        Read feedback
      </NavLink>
      <NavLink button buttonClass="is-dark" to="/how-we-work">
        Learn how we work
      </NavLink>
    </div>
  </Container>
</Section>

<!-- <Section bgColor="platinum">
  <Container>
    <p>
      In addition to offering dedicated workshops and programs for teams, we
      hold regular sessions that are open to individuals.
    </p>
    <p>If you'd be interested participating as an individual, let us know...</p>
    <button class="button is-gold" on:click={() => (formModalActive = true)}>
      Register interest
    </button>
  </Container>
</Section> -->

{#if supImages.length > 0}
  <Section bgColor="light-grey" squarePad>
    <Container>
      <div class="columns">
        {#each supImages as image}
          <div class="column">
            <img
              alt={image.split(".")[0].replace(/-/g, " ")}
              class="adaptive-600"
              src="/img/{image}"
            />
          </div>
        {/each}
      </div>
    </Container>
  </Section>
{/if}

{#if gallery}
  <Section bgColor="light-grey" squarePad>
    <Container>
      <Carousel
        auto
        component={ImageCarouselCard}
        items={gallery}
        style="height:348px"
      />
    </Container>
  </Section>
{/if}

{#if program.quote}
  <Section bgColor="jet">
    <Container>
      <Quote citation={program.quoteCitation} large paras={[program.quote]} />
    </Container>
  </Section>
{/if}

<style>
  .buttons {
    justify-content: center;
    margin: 0 !important;
  }

  .buttons :global(.button) {
    margin: 5px !important;
  }

  .description :global(.mdown img) {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .description :global(.mdown.button) {
    margin-bottom: 1rem;
    margin-top: -0.5rem;
  }

  img.cert {
    margin-bottom: 3rem;
    max-height: 400px;
  }

  :global(.description .quote) {
    background-color: var(--ps-colors-mid-grey);
    border-radius: 10px;
    color: white;
    margin-bottom: 1rem;
    padding: 1rem;
  }
</style>
