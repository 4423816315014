<script>
  import { posts } from "../blog/facade";
  import AudioPlayer from "../components/AudioPlayer.svelte";
  import BlogPostCard from "../components/BlogPostCard.svelte";
  import Container from "../components/Container.svelte";
  import MarkDown from "../components/Markdown.svelte";
  import Section from "../components/Section.svelte";

  export let id;

  const internalLinkMatcher = /href="(\/.+?)"/g;
  $: internalLinkReplacement = `href="$1?s=psbg-${id}"`;

  const allPosts = posts(true);
  $: pIndex = allPosts.findIndex((p) => p.stub == id);
  $: post = allPosts[pIndex];

  $: postTags = new Set(post.tags);
  $: relatedPosts = allPosts.filter((post) => {
    if (post.stub == id) return false;
    const tags = new Set(post.tags);
    return !tags.isDisjointFrom(postTags); // no attempt to rank
  });

  $: surroundingPosts = [
    pIndex > 0 ? allPosts[pIndex - 1] : null,
    pIndex < allPosts.length - 1 ? allPosts[pIndex + 1] : null,
  ].filter((p) => p);

  let otherPosts = [];
  $: {
    otherPosts = relatedPosts.slice(0, 2);

    let i = 0;
    while (otherPosts.length < 3) {
      let sp = surroundingPosts[i];
      if (!sp) break;
      otherPosts.push(sp);
      i++;
    }

    otherPosts.sort((a, b) => a.date - b.date);
  }
</script>

<!-- TODO: prevent internal links from triggering a browser load -->
<Section>
  <Container>
    <div class="article">
      {#if post.audio}
        <div class="audio">
          <AudioPlayer
            barPrimaryColor="var(--ps-colors-mid-grey)"
            barSecondaryColor="var(--ps-colors-light-grey)"
            display={true}
            iconColor="var(--ps-colors-mid-grey)"
            src={post.audio}
            textColor="var(--ps-colors-mid-grey)"
          />
          <p>listen to this article</p>
        </div>
        <hr />
      {/if}

      <p class="metadata">
        {post.date}
        <br />
        {#if post.wordCount}
          {Math.round(post.wordCount / 200) || 1}m read
        {/if}
      </p>

      {@html post.body.replace(internalLinkMatcher, internalLinkReplacement)}

      {#if (post.relatedText || "").length > 0}
        <hr />
        <div class="callout">
          <MarkDown
            content={post.relatedText}
            postProcess={(html) =>
              html.replace(internalLinkMatcher, internalLinkReplacement)}
          />
        </div>
      {/if}
    </div>
  </Container>

  {#if allPosts.length > 0}
    <Container innerPad>
      <div class="others">
        <hr />
        <div class="columns is-centered">
          {#each otherPosts as post}
            <div class="column is-one-third">
              <BlogPostCard {post} />
            </div>
          {/each}
        </div>
      </div>
    </Container>
  {/if}
</Section>

<style>
  /* All other styles are global in order to be shared with the blog editor */

  .article {
    margin: auto;
    max-width: 680px;
    text-align: left;
  }

  .article .audio {
    font-size: var(--ps-fontsize-tiny);
    margin-bottom: 1.5rem;
  }

  .article .audio p {
    color: var(--ps-colors-mid-grey);
    font-style: italic;
    margin: 0;
    text-align: center;
  }

  .article .audio :global(button:hover) {
    text-decoration: none;
  }

  .article .audio :global(.volume-progress) {
    display: none;
  }

  .article :global(figcaption) {
    display: none;
  }

  .article p.metadata {
    border: 1px dotted var(--ps-colors-light-grey);
    border-radius: 5px;
    color: var(--ps-colors-mid-grey);
    font-size: var(--ps-fontsize-tiny);
    float: right;
    line-height: 150%;
    margin: 0 0 0 5px;
    padding: 5px 10px;
    text-align: right;
  }

  .callout :global(.mdown *:last-child) {
    margin-bottom: 0;
  }

  .others .column {
    padding: 1rem;
    text-align: left;
  }
</style>
