<script>
  import { onMount } from "svelte";
  import { airtableData } from "../airtable/compiled";
  import Container from "../components/Container.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Section from "../components/Section.svelte";

  let chart, chartWidth;

  // From https://coolors.co/ffadad-ffd6a5-fdffb6-caffbf-9bf6ff-a0c4ff-bdb2ff-ffc6ff-fffffc
  const colors = {
    blue: "a0c4ff",
    cyan: "9bf6ff",
    green: "caffbf",
    mauve: "ffc6ff",
    orange: "ffd6a5",
    purple: "bdb2ff",
    red: "ffadad",
    snow: "fffffc",
    yellow: "fdffb6",
  };

  const colorsByFeeling = {
    angry: "red",
    disgusted: "orange",
    fearful: "purple",
    happy: "green",
    meh: "blue",
    sad: "cyan",
    surprised: "yellow",
  };

  function rgbFromHex(hex) {
    return hex.match(/.{1,2}/g).map((h) => parseInt(h, 16));
  }

  const f = 1.05;

  function darkest([r, g, b], factor = f * f) {
    return [r / factor, g / factor, b / factor];
  }

  function lighten([r, g, b], factor = f) {
    return [r * factor, g * factor, b * factor];
  }

  const data = { name: "root", children: [] };

  for (const f of airtableData.feelings) {
    const cats = data.children;
    let cat = cats.find((c) => c.name == f.cat);
    if (!cat) {
      cat = {
        name: f.cat,
        children: [],
        color: darkest(
          rgbFromHex(colors[colorsByFeeling[f.cat.toLowerCase()]])
        ),
      };
      cats.push(cat);
    }

    const subCats = cat.children;
    let subCat = subCats.find((c) => c.name == f.subCat);
    if (!subCat) {
      subCat = { name: f.subCat, children: [], color: lighten(cat.color) };
      subCats.push(subCat);
    }

    subCat.children.push({
      name: f.name,
      value: 1,
      color: lighten(subCat.color),
    });
  }

  onMount(() => {
    // TODO: handle the failure to load the sunburst library from external site
    const build = Sunburst()
      .color(({ color }) => {
        return `rgb(${(color || [255, 255, 255]).join(",")})`;
      })
      .data(data)
      .excludeRoot(true)
      .height(chartWidth)
      .labelOrientation("radial")
      .radiusScaleExponent(1)
      .showTooltip(() => null)
      .transitionDuration(100)
      .width(chartWidth);

    build(chart);
  });
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>Emotions Wheel</h1>
    <h3>"I notice I'm feeling..."</h3>
    <p>
      General emotions in the center. Specifics at the edge. <br />
      <span class="is-hidden-mobile">
        Click on an emotion to focus. Click in the center to go back.
      </span>
      <span class="is-hidden-tablet">
        On bigger screens, the wheel is interactive. <br /> Click / tap to download
        a copy.
      </span>
    </p>
  </Container>
</Section>

<Section bgColor="gold" noPad>
  <Container>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <p id="burnout-cta">
          To explore more tools for <strong>emotional intelligence</strong>,
          <strong>mindfulness</strong> and <strong>resilience</strong>, check
          out our Preventing Burnout workshop.
        </p>

        <NavLink button buttonClass="is-dark" to="/w/wellbeing">
          Learn more
        </NavLink>
      </div>
    </div>
  </Container>
</Section>

<Section>
  <Container>
    <div class="is-hidden-mobile">
      <p id="copyright">© {new Date().getFullYear()} <br /> PeopleStorming</p>
      <div bind:this={chart} bind:clientWidth={chartWidth} id="chart" />
    </div>

    <a
      aria-label="download emotions wheel"
      class="is-hidden-tablet"
      href="/img/emotions-wheel.png"
    >
      <img alt="emotions wheel" src="/img/emotions-wheel.png" />
    </a>
  </Container>
</Section>

<style>
  :global(.sunburst-viz .text-contour) {
    stroke: none !important;
  }

  :global(.sunburst-viz .text-stroke) {
    fill: var(--ps-colors-jet);
    font-family: var(--ps-fonts-body);
    font-size: var(--ps-fontsize-tiny);
  }

  p#copyright {
    font-size: xx-small;
    height: inherit;
    left: 0;
    margin-top: -1.2em;
    position: absolute;
    right: 0;
    top: 50%;
    width: inherit;
  }

  p#burnout-cta {
    margin-bottom: 1rem;
  }

  .columns {
    margin: 0;
    padding: 1rem;
  }

  .columns .column {
    padding: 0;
  }
</style>
