<script>
  import BlogPostCard from "../components/BlogPostCard.svelte";
  import Container from "../components/Container.svelte";
  import Section from "../components/Section.svelte";

  import { posts } from "../blog/facade";

  const allPosts = posts(true);

  const tagFreqs = {};
  for (const post of allPosts) {
    for (const tag of post.tags) {
      tagFreqs[tag] = (tagFreqs[tag] || 0) + 1;
    }
  }
  const tagFreqsSorted = Object.entries(tagFreqs).sort((a, b) => b[1] - a[1]);

  $: filter = "";
  $: filteredPosts = [];
  $: {
    const filterWords = (filter || "")
      .split(" ")
      .map((w) => w.toLocaleLowerCase().trim())
      .filter((w) => w);

    if (filterWords.length == 0) filteredPosts = allPosts;
    else
      filteredPosts = allPosts.filter((post) =>
        filterWords.every(
          (w) =>
            post.summary.toLocaleLowerCase().includes(w) ||
            post.title.toLocaleLowerCase().includes(w) ||
            post.tags.find((t) => t.toLocaleLowerCase().includes(w))
        )
      );
  }

  $: postSlices = filteredPosts.reduce((slices, post, i) => {
    const sliceId = Math.floor(i / 3);
    slices[sliceId] = (slices[sliceId] || []).concat([post]);
    return slices;
  }, []);
</script>

<Section>
  <Container>
    <div class="filter">
      <input type="text" placeholder="👀 filter..." bind:value={filter} />
      {#if tagFreqsSorted.length > 0}
        <div class="popular">
          {#each tagFreqsSorted.slice(0, 6) as [tag]}
            <button on:click={() => (filter = tag)}>{tag}</button>
          {/each}
        </div>
      {/if}
    </div>

    {#each postSlices as slice}
      <div class="columns">
        {#each slice as post (post.stub)}
          <div class="column is-one-third">
            <BlogPostCard {post} />
          </div>
        {/each}
      </div>
    {/each}
  </Container>
</Section>

<style>
  .column {
    padding: 1rem;
    text-align: left;
  }

  .filter {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: var(--ps-fontsize-tiny);
    font-style: italic;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .filter input {
    border: 1px dotted var(--ps-colors-jet);
    border-radius: 5px;
    font-size: var(--ps-fontsize-tiny);
    padding: 0.5rem;
  }

  .filter .popular {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  .filter .popular button {
    background: none;
    border: 1px dotted var(--ps-colors-jet);
    border-radius: 5px;
    cursor: pointer;
    font-size: var(--ps-fontsize-tiny);
    text-decoration: none;
  }

  .filter .popular button:hover {
    border-style: solid;
  }
</style>
