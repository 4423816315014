<script>
  import { fade } from "svelte/transition";

  import { posts } from "../blog/facade";
  import Assessment from "../components/Assessment.svelte";
  import BlogPostCard from "../components/BlogPostCard.svelte";
  import Container from "../components/Container.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";

  let band = null;
  let lowQuestions = [];
  let results = null;
  function onComplete({ answersWithColors, assessment, responses, sections }) {
    results = { answersWithColors, assessment, responses, sections };

    let total = responses
      .flatMap((r) => r.answers)
      .reduce((sum, a) => sum + a + 1, 0);

    // 5 equal blocks from 9 to 45, allocating the carried 2 items into the lowest two bands
    if (total < 17) band = 0;
    else if (total < 25) band = 1;
    else if (total < 32) band = 2;
    else if (total < 39) band = 3;
    else band = 4;

    for (const { answers, section } of responses) {
      for (let i = 0; i < answers.length; i++) {
        if (answers[i] < 2) lowQuestions.push(section.questions[i].text);
      }
    }
  }

  const relevantPostStubs = [
    "10-ways-for-leaders-to-build-world",
    "building-more-psychological-safety",
    "festival-of-failure-ext",
  ];

  const relevantPosts = posts(true).filter((p) =>
    relevantPostStubs.includes(p.stub)
  );
</script>

<Assessment {onComplete} stub="psych-safety-assessment">
  {#if results}
    <Section>
      <Container>
        <div in:fade={{ duration: 500 }}>
          {#if band == 0}
            <header class="color-bg-jet">
              <h2>Result: Very Low Psychological Safety</h2>
              <div class="score">
                {#each results.answersWithColors as { color }, i}
                  <div
                    class="block"
                    class:emph={i == band}
                    style="background-color: #{color}"
                  />
                {/each}
              </div>
            </header>

            <div class="text color-bg-snow">
              <p>
                Your answers suggest significant psychological safety challenges
                that require immediate, compassionate attention.
              </p>

              <p class="list-intro">
                Teams experiencing this level of psychological safety often
                struggle with...
              </p>
              <ul>
                <li>High levels of interpersonal risk</li>
                <li>Reduced innovation potential</li>
                <li>Increased stress and potential burnout</li>
              </ul>

              <p>
                These factors can lead to problems with performance, retention
                and even legal risks.
              </p>

              {#if lowQuestions.length > 0}
                <div class="low-scorers">
                  <h3>Low-scoring Statements</h3>
                  <ul>
                    {#each lowQuestions as question}
                      <li>{question}</li>
                    {/each}
                  </ul>
                </div>
              {/if}

              <h3>Action Steps</h3>
              <ul>
                <li>Speak to your manager and/or HRBP to get support</li>
                <li>
                  Approach this as a shared organizational challenge, avoiding
                  blame language
                </li>
                <li>Engage with curiosity and openness</li>
                <li>
                  Consider a conversation with a neutral third-party to provide
                  objective insights
                </li>
              </ul>

              <h3>Potential Support Options</h3>
              <ul>
                <li>
                  <a href="/coaching" target="_blank">Individual coaching</a> (sometimes
                  needed for managers)
                </li>
                <li>
                  A <a href="/w/psych-safety" target="_blank"
                    >Psychological Safety Workshop</a
                  > for your team and/or the wider organization
                </li>
                <li>
                  <a href="/consulting" target="_blank"
                    >Comprehensive diagnostic consultation</a
                  > to understand root causes
                </li>
              </ul>
            </div>

            <div class="quote color-bg-light-gold">
              <Quote
                citation="Laozi - Tao Te Ching"
                paras={[
                  "A journey of a thousand miles begins with a single step.",
                ]}
              />
            </div>
          {:else if band == 1}
            <header class="color-bg-jet">
              <h2>Result: Low Psychological Safety</h2>
              <div class="score">
                {#each results.answersWithColors as { color }, i}
                  <div
                    class="block"
                    class:emph={i == band}
                    style="background-color: #{color}"
                  />
                {/each}
              </div>
            </header>

            <div class="text color-bg-snow">
              <p>
                Your answers suggest significant challenges that, if left
                unaddressed, could further deteriorate and cause substantial
                organizational damage.
              </p>

              <p class="list-intro">
                Teams experiencing this level of psychological safety often
                have...
              </p>
              <ul>
                <li>Notable gaps in team trust</li>
                <li>Limited open communication</li>
                <li>Potential barriers to collaborative problem-solving</li>
              </ul>

              {#if lowQuestions.length > 0}
                <div class="low-scorers">
                  <h3>Low-scoring Statements</h3>
                  <ul>
                    {#each lowQuestions as question}
                      <li>{question}</li>
                    {/each}
                  </ul>
                </div>
              {/if}

              <h3>Action Steps</h3>
              <ul>
                <li>Speak to your manager and/or HRBP to get support</li>
                <li>
                  Carefully examine the lowest-scoring areas of the assessment
                </li>
                <li>Begin thinking about targeted improvement strategies</li>
                <li>
                  Consider a conversation with a neutral third-party to provide
                  objective insights
                </li>
              </ul>

              <h3>Potential Support Options</h3>
              <ul>
                <li>
                  <a href="/coaching" target="_blank">Individual coaching</a> (sometimes
                  needed for managers)
                </li>
                <li>
                  A <a href="/w/psych-safety" target="_blank"
                    >Psychological Safety Workshop</a
                  > for your team and/or the wider organization
                </li>
                <li>
                  <a href="/consulting" target="_blank"
                    >Comprehensive diagnostic consultation</a
                  > to understand root causes
                </li>
              </ul>
            </div>

            <div class="quote color-bg-light-gold">
              <Quote
                citation="Will McAvoy - The Newsroom"
                paras={[
                  "The first step in solving a problem is recognizing there is one.",
                ]}
              />
            </div>
          {:else if band == 2}
            <header class="color-bg-jet">
              <h2>Result: Moderate Psychological Safety</h2>
              <div class="score">
                {#each results.answersWithColors as { color }, i}
                  <div
                    class="block"
                    class:emph={i == band}
                    style="background-color: #{color}"
                  />
                {/each}
              </div>
            </header>

            <div class="text color-bg-snow">
              <p>
                Your answers suggest a moderate level of psychological safety
                with plenty of room for improvement. Your team has foundational
                elements of trust, but there are clear opportunities to enhance
                team dynamics and communication.
              </p>

              <p class="list-intro">
                Teams experiencing this level of psychological safety often
                have...
              </p>
              <ul>
                <li>Emerging patterns of open dialogue</li>
                <li>Untapped potential for increased collaboration</li>
                <li>Valuable opportunities for strategic team development</li>
              </ul>

              {#if lowQuestions.length > 0}
                <div class="low-scorers">
                  <h3>Low-scoring Statements</h3>
                  <ul>
                    {#each lowQuestions as question}
                      <li>{question}</li>
                    {/each}
                  </ul>
                </div>
              {/if}

              <h3>Action Steps</h3>
              <ul>
                <li>Aim to open a dialogue with your team</li>
                <li>Discuss what's working well and what could be improved</li>
                <li>Invite the entire team to take this assessment</li>
                <li>Create a collaborative environment for sharing insights</li>
              </ul>

              <h3>Potential Support Options</h3>
              <ul>
                <li>
                  A <a href="/w/psych-safety" target="_blank"
                    >Psychological Safety Workshop</a
                  > for your team and/or the wider organization
                </li>

                <li>
                  <a href="/p/chartering-program" target="_blank"
                    >Facilitated roundtable discussions</a
                  > exploring team potential
                </li>
                <li>
                  <a href="/coaching" target="_blank">Individual coaching</a> (sometimes
                  needed for managers)
                </li>
              </ul>
            </div>

            <div class="quote color-bg-light-gold">
              <Quote
                citation="George Bernard Shaw"
                paras={[
                  "Progress is impossible without change, and those who cannot change their minds cannot change anything.",
                ]}
              />
            </div>
          {:else if band == 3}
            <header class="color-bg-jet">
              <h2>Result: High Psychological Safety</h2>
              <div class="score">
                {#each results.answersWithColors as { color }, i}
                  <div
                    class="block"
                    class:emph={i == band}
                    style="background-color: #{color}"
                  />
                {/each}
              </div>
            </header>

            <div class="text color-bg-snow">
              <p>
                Your answers suggest a high level of psychological safety. Your
                team is demonstrating strong foundational elements of trust,
                open communication, and collaborative potential.
              </p>

              <p class="list-intro">
                Teams experiencing this level of psychological safety often
                have...
              </p>
              <ul>
                <li>Consistent patterns of open dialogue</li>
                <li>Good levels of team trust</li>
                <li>Willingness to share ideas and take calculated risks</li>
              </ul>

              {#if lowQuestions.length > 0}
                <div class="low-scorers">
                  <h3>Low-scoring Statements</h3>
                  <ul>
                    {#each lowQuestions as question}
                      <li>{question}</li>
                    {/each}
                  </ul>
                </div>
              {/if}

              <h3>Action Steps</h3>
              <ul>
                <li>Talk with the team about your assessment results</li>
                <li>Invite other team members to do their own assessment</li>
                <li>
                  Be prepared to have an open dialogue about potential areas for
                  improvement
                </li>
                <li>
                  Consider integrating your insights into regular team
                  retrospectives
                </li>
              </ul>

              <h3>Potential Support Options</h3>
              <ul>
                <li>
                  <a
                    href="https://peoplestorming.substack.com/p/from-solo-leaders-to-manager-communities"
                    target="_blank"
                    rel="noopen">Facilitated roundtable discussions</a
                  > exploring team potential
                </li>
                <li>
                  Themed discussions to maintain and enhance psychological
                  safety
                </li>
                <li>
                  <a href="/p/chartering-program" target="_blank"
                    >Strategic planning sessions</a
                  > to build on current strengths
                </li>
                <li>
                  A <a href="/w/psych-safety" target="_blank"
                    >Psychological Safety Workshop</a
                  > to enhance shared language and foster fresh ideas for improvement
                </li>
              </ul>
            </div>

            <div class="quote color-bg-light-gold">
              <Quote
                citation="Amy Edmondson"
                paras={[
                  "Psychological safety is not about being nice. It's about creating an environment where people feel they can be honest, take risks, and learn from mistakes.",
                ]}
              />
            </div>
          {:else if band == 4}
            <header class="color-bg-jet">
              <h2>Result: Very High Psychological Safety</h2>
              <div class="score">
                {#each results.answersWithColors as { color }, i}
                  <div
                    class="block"
                    class:emph={i == band}
                    style="background-color: #{color}"
                  />
                {/each}
              </div>
            </header>

            <div class="text color-bg-snow">
              <p>
                Your answers suggest a very high level of psychological safety.
                Your team is demonstrating very strong communication, trust and
                collaboration skills. But, remember that a happy, healthy, high
                performing team requires continuous attention and nurturing.
              </p>

              <p class="list-intro">
                Teams experiencing this level of psychological safety often
                have...
              </p>
              <ul>
                <li>Consistent patterns of open dialogue</li>
                <li>Very high levels of team trust</li>
                <li>High performance and strong group-mind</li>
              </ul>

              {#if lowQuestions.length > 0}
                <div class="low-scorers">
                  <h3>Low-scoring Statements</h3>
                  <ul>
                    {#each lowQuestions as question}
                      <li>{question}</li>
                    {/each}
                  </ul>
                </div>
              {/if}

              <h3>Action Steps</h3>
              <ul>
                <li>Talk with the team about your assessment results</li>
                <li>Invite other team members to do their own assessment</li>
                <li>
                  Reflect on what can your team be doing to continuously grow
                  and foster psychological safety as the highest performing
                  teams prioritize continuous learning
                </li>
                <li>
                  Challenge the team to identify behaviors and practices they
                  admire in other people/teams and would like to experiment with
                </li>
              </ul>

              <h3>Potential Support Options</h3>
              <ul>
                <li>
                  <a
                    href="https://peoplestorming.substack.com/p/from-solo-leaders-to-manager-communities"
                    target="_blank"
                    rel="noopen">Facilitated roundtable discussions</a
                  > on the topic of continuous learning
                </li>
                <li>
                  A review of your retrospective culture, practices and rituals
                </li>
                <li>
                  <a href="/p/chartering-program" target="_blank"
                    >Strategic planning sessions</a
                  > to build on current strengths
                </li>
              </ul>
            </div>

            <div class="quote color-bg-light-gold">
              <Quote
                citation="Brian Tracy"
                paras={[
                  "Excellence is not a destination; it is a continuous journey that never ends.",
                ]}
              />
            </div>
          {/if}
        </div>
      </Container>
    </Section>
  {/if}
</Assessment>

<Section>
  <Container innerPad>
    <div class="others">
      <hr />
      <div class="columns is-centered">
        {#each relevantPosts as post}
          <div class="column is-one-third">
            <BlogPostCard {post} />
          </div>
        {/each}
      </div>
    </div>
  </Container>
</Section>

<style>
  header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 auto;
    max-width: 680px;
    padding: 1em 1.5em;
  }

  header h2 {
    font-size: var(--ps-fontsize-medlarge);
    margin-bottom: 1.5rem;
  }

  header .score {
    display: flex;
    justify-content: center;
  }

  header .score .block {
    height: 50px;
    opacity: 25%;
    width: 50px;
  }

  header .score .block.emph {
    opacity: 90%;
  }

  .quote {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0 auto;
    max-width: 680px;
    padding: 1em 1.5em;
  }

  .text {
    margin: 0 auto;
    max-width: 680px;
    padding: 1em 1.5em;
    text-align: left;
  }

  .text .low-scorers {
    border: 1px dotted maroon;
    border-radius: 5px;
    color: maroon;
    font-size: smaller;
    margin-bottom: 1.5rem;
    padding: 0.5rem 1rem;
  }

  .text .low-scorers ul {
    margin: 0;
    padding-left: 0.5rem;
  }

  .text .low-scorers ul li {
    margin-left: 1rem;
  }

  .text a:not(:hover) {
    text-decoration: underline;
    text-decoration-color: gray;
  }

  .text h3 {
    display: flex;
    font-size: var(--ps-fontsize-small);
    margin-bottom: 0.75rem;
  }

  .text p.list-intro {
    margin-bottom: 0.5rem;
  }

  .text ul {
    margin-bottom: 2rem;
  }

  .text ul li {
    list-style-type: disc;
    margin-bottom: 0.5rem;
    margin-left: 2.5rem;
  }
</style>
