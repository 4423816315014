<script>
  import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
  import { undrawImageAlt } from "../undrawImageAlts";
  import Icon from "./Icon.svelte";

  export let citation;
  export let hideIcon;
  export let large;
  export let midsize;
  export let paras;
  export let photo;
  export let photoInline;
  export let photoSmall;
  export let title;
  export let titleHeight;
  export let titleHeightMax;

  const genericImageAlts = {
    FEMALE: undrawImageAlt("female_avatar_w3jk"),
    MALE: undrawImageAlt("male_avatar_323b"),
  };

  const genericImageSrcs = {
    FEMALE: "/img/illustrations/gold/undraw_female_avatar_w3jk.svg",
    MALE: "/img/illustrations/gold/undraw_male_avatar_323b.svg",
  };

  $: imageAlt =
    genericImageAlts[photo] ||
    (photo ? photo.split(".").slice(0, -1).join(" ") : null);

  $: imageSrc = genericImageSrcs[photo] || `/img/people/${photo}`;

  const photoSizeClass = `adaptive-${photoSmall ? 100 : 200}`;
</script>

<div class="quote" class:inline={photoInline}>
  {#if title}
    <header style={titleHeightMax ? `min-height:${titleHeightMax}px` : ""}>
      <h4 bind:clientHeight={titleHeight}>{title}</h4>
    </header>
  {/if}
  {#if photo && !photoInline}
    <img alt={imageAlt} class="{photoSizeClass} mb1r rounded" src={imageSrc} />
  {/if}
  {#if !hideIcon}
    <Icon i={faQuoteRight} />
  {/if}
  <blockquote>
    {#each paras.filter((p) => p) as para, i}
      {#if photoInline && i == 0}
        <div style="display:flex; margin-bottom: 1rem;">
          <img
            alt={imageAlt}
            class="{photoSizeClass} rounded"
            style="align-self:center; margin-right: 1rem;"
            src={imageSrc}
          />
          <p class:large class:midsize>
            {para}
          </p>
        </div>
      {:else}
        <p class:large class:midsize>
          {para}
        </p>
      {/if}
    {/each}
    <p class="citation">
      <cite>{citation}</cite>
    </p>
  </blockquote>
</div>

<style>
  .quote {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  blockquote {
    padding-top: 10px;
  }

  header {
    margin-bottom: 1rem;
  }

  p {
    /* clear: both; TODO: check this doesn't adversly affect non-inline quotes */
    font-size: var(--ps-fontsize-tiny);
  }

  p.large {
    font-size: var(--ps-fontsize-medium);
  }

  p.midsize {
    font-size: var(--ps-fontsize-small);
  }

  .inline {
    text-align: left;
  }

  p.citation {
    text-align: center;
  }
</style>
