<script>
  import { flip } from "svelte/animate";
  import { fade, fly } from "svelte/transition";

  import { airtableData } from "../airtable/compiled";
  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import Section from "../components/Section.svelte";
  import { assessmentResponses } from "../stores";

  import {
    faHeart,
    faHourglass,
    faQuestionCircle,
  } from "@fortawesome/free-regular-svg-icons";
  import {
    faBrain,
    faFrown,
    faHands,
    faMehRollingEyes,
    faMugHot,
    faPeopleArrows,
    faStar,
    faTired,
  } from "@fortawesome/free-solid-svg-icons";
  const icons = {
    faBrain,
    faFrown,
    faHands,
    faHeart,
    faHourglass,
    faMehRollingEyes,
    faMugHot,
    faPeopleArrows,
    faQuestionCircle,
    faStar,
    faTired,
  };

  export let onComplete;
  export let random;
  export let stub;

  $: assessment = airtableData.assessments.find((a) => a.stub == stub);

  $: sections = airtableData.assessmentSections
    .filter((s) => s.assessmentStub == stub)
    .sort((a, b) => a.seq - b.seq);

  $: answersWithColors = assessment.answers.split("\n").map((line) => {
    const [answer, color] = line.trim().split(/ #/);
    return {
      answer,
      color,
    };
  });

  $: if (!$assessmentResponses[stub])
    $assessmentResponses[stub] = [
      {
        answers: [],
        icon: icons[sections[0].icon],
        section: sections[0],
      },
    ];
  $: responses = $assessmentResponses[stub];

  $: progress = responses.reduce((sum, r) => sum + r.answers.length, 1);
  $: progressMax = sections.reduce((sum, sec) => sum + sec.questions.length, 0);

  let onCompleteInvoked = false;
  $: if (progress > progressMax) {
    onComplete({ answersWithColors, assessment, responses, sections });
    onCompleteInvoked = true;
  }

  function onRespond(answer) {
    const r = responses[responses.length - 1];
    r.answers.push(answer);

    if (
      r.answers.length == r.section.questions.length &&
      responses.length < sections.length
    ) {
      const section = sections[responses.length];
      responses.push({
        answers: [],
        icon: icons[section.icon],
        section,
      });
    }

    $assessmentResponses[stub] = responses;
  }

  if (random) {
    while (!onCompleteInvoked)
      onRespond(Math.round(Math.random() * (answersWithColors.length - 1)));
  }
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>{assessment.title}</h1>
    <p>
      {assessment.summary}
    </p>
    {#if assessment.disclaimerHead}
      <p>
        <strong>
          {assessment.disclaimerHead}{assessment.disclaimerBody ? "*" : ""}
        </strong>
      </p>
    {/if}
  </Container>
</Section>

{#if !onCompleteInvoked}
  <Section>
    <Container>
      <progress
        class="progress is-small is-warning overall"
        value={progress}
        max={progressMax}
      >
        {progress}/{progressMax}
      </progress>
      {#each responses.slice(-1) as { section, answers } (responses.length)}
        <div
          in:fade={responses.length > 1 ? { duration: 500 } : { duration: 0 }}
        >
          {#if sections.length > 1}
            <h2>
              Part {responses.length}: {section.name}
            </h2>
          {/if}

          {#each [section.questions[answers.length]] as question (answers.length)}
            <div
              animate:flip={answers.length > 0}
              in:fly={answers.length > 0
                ? { x: 200, duration: 500 }
                : { duration: 0 }}
              out:fly={answers.length > 0 &&
              answers.length < section.questions.length
                ? { x: -200, duration: 500 }
                : { duration: 0 }}
              class="step"
            >
              <p class="statement">{question.text}...</p>
              <table class="rating">
                {#each answersWithColors as { answer, color }, i}
                  <tr on:click={() => onRespond(i)}>
                    <td class="star" style="color:#{color}; opacity:0.3">
                      <Icon i={faStar} />
                    </td>
                    <td>
                      {answer}
                    </td>
                  </tr>
                {/each}
              </table>
            </div>
          {/each}
        </div>
      {/each}
    </Container>
  </Section>
{/if}

<slot />

{#if assessment.disclaimerBody}
  <Section sidePad>
    <Container>
      <div class="columns is-centered">
        <div class="column is-two-thirds">
          <p id="disclaimer">
            {assessment.disclaimerHead ? "*" : ""}
            {assessment.disclaimerBody}
          </p>
        </div>
      </div>
    </Container>
  </Section>
{/if}

<style>
  .step {
    border: 1px solid var(--ps-colors-light-grey);
    border-radius: 5px;
    margin: 0 auto;
    max-width: 600px;
    padding: 1rem;
  }

  .step .statement {
    font-weight: bold;
  }

  .rating {
    margin: 0 auto;
  }

  .rating td {
    padding: 10px 5px;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: var(--ps-colors-light-grey);
  }

  .rating tr:hover * {
    cursor: pointer;
    font-weight: bold;
    opacity: 1 !important;
  }

  h2 {
    font-size: var(--ps-fontsize-medlarge);
    margin-bottom: 1.5rem;
  }

  progress.overall {
    margin: 0 auto 1.5rem;
    width: revert;
  }

  #disclaimer {
    font-size: var(--ps-fontsize-tiny);
  }
</style>
