import Blog from "./routes/Blog.svelte";
import BlogPost from "./routes/BlogPost.svelte";
import BookBuy from "./routes/BookBuy.svelte";
import BurnoutAssessment from "./routes/BurnoutAssessment.svelte";
import BurnoutGuide from "./routes/BurnoutGuide.svelte";
import CaseStudy from "./routes/CaseStudy.svelte";
import Coaching from "./routes/Coaching.svelte";
import CoachingOptions from "./routes/CoachingOptions.svelte";
import CoachingTechRefs from "./routes/CoachingTechRefs.svelte";
import Consulting from "./routes/Consulting.svelte";
import DTOG from "./routes/DTOG.svelte";
import EmotionsWheel from "./routes/EmotionsWheel.svelte";
import Home from "./routes/Home.svelte";
import HowWeWork from "./routes/HowWeWork.svelte";
import LeadershipResourceList from "./routes/LeadershipResourceList.svelte";
import LeadershipSpeaker from "./routes/LeadershipSpeaker.svelte";
import ListeningAssessment from "./routes/ListeningAssessment.svelte";
import MeetingCostCalc from "./routes/MeetingCostCalc.svelte";
import OurTeam from "./routes/OurTeam.svelte";
import Program from "./routes/Program.svelte";
import PsychSafetyAssessment from "./routes/PsychSafetyAssessment.svelte";
import Signup from "./routes/Signup.svelte";
import SignupThanks from "./routes/SignupThanks.svelte";
import Tools from "./routes/Tools.svelte";
import Upgrade from "./routes/Upgrade.svelte";
import WhyUs from "./routes/WhyUs.svelte";
import Workshop from "./routes/Workshop.svelte";
import Workshops from "./routes/Workshops.svelte";

export const routesByPath = {
  // ROOT
  "": {
    children: [
      "/",
      "/coaching-root",
      "/workshops",
      "/programs",
      "/consulting",
      "/book-call",
      "/resources",
      "/blog",
      "/about",
    ],
  },

  // Home
  "/": {
    component: Home,
    name: "Home",
    title: "PeopleStorming - Happy, Healthy, High-performing Teams",
    description:
      "We provide playful, thought-provoking coaching and training for leaders and teams who want to achieve great things",
  },

  // Coaching
  "/coaching-root": {
    name: "Coaching",
    children: ["/coaching", "/coaching-options"],
  },
  "/coaching": {
    component: Coaching,
    name: "Coaching Information",
    description:
      "We provide a modern approach to coaching for new, growing and established professionals across the world 🌎",
    title: "Coaching | PeopleStorming",
  },
  "/coaching-options": {
    component: CoachingOptions,
    name: "Coaching Fees",
    description: "Flexible options to get the expert coaching you need",
    title: "Coaching Options | PeopleStorming",
  },
  "/engineer-coaching": {
    component: Coaching,
    name: "Engineer Coaching",
    description:
      "We provide a modern approach to coaching for new, growing and established engineers across the world 🌎",
  },
  "/engineering-leadership-coaching": {
    component: Coaching,
    name: "Engineering Leadership Coaching",
    description:
      "We provide a modern approach to coaching for new, growing and established engineering leaders across the world 🌎",
  },
  "/executive-leadership-coaching": {
    component: Coaching,
    name: "Executive Leadership Coaching",
    description:
      "We provide a modern approach to coaching for new, growing and established leaders across the world 🌎",
  },
  "/people-leadership-coaching": {
    component: Coaching,
    name: "People Leadership Coaching",
    description:
      "We provide a modern approach to coaching for new, growing and established people leaders across the world 🌎",
  },
  "/product-management-coaching": {
    component: Coaching,
    name: "Product Management Coaching",
    description:
      "We provide a modern approach to coaching for new, growing and established product managers across the world 🌎",
  },
  "/coaching-tech-refs": {
    component: CoachingTechRefs,
    name: "Technology Coaching References",
    description:
      "Check out our coaching client feedback from the tech industry",
  },

  // Programs, Upgrades, Workshops
  "/workshops": {
    component: Workshops,
    name: "Workshops",
    description:
      "We provide playful, thought-provoking coaching and training for leaders and teams who want to achieve great things",
  },
  "/programs": {
    name: "Programs",
    childrenFlat: {
      "/p/leadership-program": "Leadership",
      "/p/chartering-program": "Team Chartering",
      "/p/retreats-program": "Retreats",
    },
  },
  "/p/:id": {
    component: Program,
  },
  "/u/:id": {
    component: Upgrade,
  },
  "/w/:id": {
    component: Workshop,
  },

  // Consulting
  "/consulting": {
    component: Consulting,
    name: "Consulting",
    description:
      "⭐️ Fresh thinking to help you build and sustain a thriving organization | Change Management | Culture Building | Leadership Alignment | Organizational Design",
  },

  // Booking / buying
  "/book-call": {
    component: BookBuy,
    name: "Book A Call",
    description: "📞 Book a call and let us know how we can be of service",
    page: "book",
  },
  "/book-disco-call": {
    component: BookBuy,
    name: "Book A Discovery Call",
    description: "📞 Book a call and let us know how we can be of service",
    page: "book-disco",
    stealth: true,
  },
  "/buy-coaching-plan": {
    component: BookBuy,
    name: "Buy A Coaching Membership",
    description:
      "Expert coaching on demand with a PeopleStorming coaching membership",
    page: "buy",
  },

  // Resources
  "/resources": {
    name: "Resources",
    children: [
      "/tools",
      "/burnout-guide",
      "/burnout-assessment",
      "/emotions-wheel",
      "/listening-assessment",
      "/leadership-resource-list",
      "/meeting-cost-calc",
      "/psych-safety-assessment",
    ],
  },
  "/burnout-guide": {
    component: BurnoutGuide,
    name: "Burnout Guide",
    description:
      "A free, three-part guide to understanding, preventing and managing burnout",
  },
  "/burnout-assessment": {
    component: BurnoutAssessment,
    name: "Burnout Assessment",
    description: "A tool to help you assess your burnout indicators",
  },
  "/dtog": {
    component: DTOG,
    name: "Digital Tools Onboarding Guide",
    description:
      "A guide to help you onboard the tools you'll be using in your workshop",
    stealth: true,
  },
  "/emotions-wheel": {
    component: EmotionsWheel,
    name: "Emotions Wheel",
    description: "A tool to help you zero-in on emotional experiences",
    stealth: true,
  },
  "/leadership-resource-list": {
    component: LeadershipResourceList,
    name: "Leadership Resource List",
    description: "Things to check out to kickstart your leadership journey",
  },
  "/listening-assessment": {
    component: ListeningAssessment,
    name: "Listening Skills Assessment",
    description: "A tool to help you assess how well you listen",
  },
  "/meeting-cost-calc": {
    component: MeetingCostCalc,
    name: "Meeting Cost Calculator",
    description: "A tool to help you assess the total cost of a meeting",
  },
  "/psych-safety-assessment": {
    component: PsychSafetyAssessment,
    name: "Psychological Safety Assessment",
    description:
      "A tool to help you assess your team's level psychologically safety",
  },
  "/tools": {
    component: Tools,
    name: "Tools For Subscribers",
    description: "Get access to a variety of tools to help support you at work",
  },
  "/tools-fulfill": {
    component: Tools,
    name: "Tools For Subscribers",
    description: "Get access to a variety of tools to help support you at work",
    stealth: true,
  },

  // Blog
  "/blog": {
    component: Blog,
    name: "Blog",
    description:
      "📖 Read articles from the PeopleStorming team on a range of topics relating to happy, healthy, high-performing teams",
  },
  "/post/:id": {
    component: BlogPost,
    name: "BlogPost",
  },

  // About
  "/about": {
    name: "About",
    children: ["/our-team", "/how-we-work", "/why-us-facilitation"],
  },
  "/how-we-work": {
    component: HowWeWork,
    name: "How We Work",
    description:
      "Learn about the tools, methods and approaches that make the PeopleStorming approach so effective",
  },
  "/our-team": {
    component: OurTeam,
    name: "Our Team",
    description: "Learn about the PeopleStorming team - come and say hello! 👋🏼",
  },
  "/why-us-coaching": {
    component: WhyUs,
    name: "Why Us - Coaching",
    description: "Check out our coaching client feedback and case studies",
    testimonials: "coaching",
  },
  "/why-us-facilitation": {
    component: WhyUs,
    name: "Why Us",
    description: "Check out our facilitation client feedback and case studies",
    testimonials: "facilitation",
    title: "Why Us - Facilitation | PeopleStorming",
  },
  "/why-us-training": {
    component: WhyUs,
    name: "Why Us - Training",
    description: "Check out our training client feedback and case studies",
    testimonials: "training",
  },

  // Signup
  "/signup": {
    component: Signup,
    name: "Join Our Community",
    description:
      "Get tips, tools and inspiration on collaboration, communication and culture straight to your inbox, twice a month.",
  },
  //TODO: remove this page once the Mailchimp workflow is confirmed dead
  "/signup-thanks": {
    component: SignupThanks,
    name: "Thanks For Signing Up",
    description:
      "Thanks for joining our community - look out for tips, tools and inspiration in your inbox",
    stealth: true,
  },

  // Case Studies
  "/case/:id": {
    component: CaseStudy,
    stealth: true,
  },

  // Speaking
  "/leadership-speaker": {
    component: LeadershipSpeaker,
    name: "Speaking Engagements",
    description: "Tech leadership speaker",
  },
};

for (const path in routesByPath) {
  const route = routesByPath[path];
  route.path = path;
  route.title = route.title || `${route.name} | PeopleStorming`;
}
